import React from "react";
import Navbar from "../components/molecules/navbar";

class BussinessConsult extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isEnglish: false,
        };
    }
    render() {
        return (
            <div className="new-page open">
                <div className="button-div" onClick={()=>this.setState({isEnglish: !this.state.isEnglish})}>
                  <p className={!this.state.isEnglish ? 'active' : 'passive'}>SR</p>
                  <p className={this.state.isEnglish ? 'active' : 'passive'}>EN</p>
                </div>
                <Navbar isEnglish={this.state.isEnglish}></Navbar>
                <div className={!this.state.isEnglish ? 'title visible' : 'title hidden'}>
                    Otvaranje firme
                </div>
                <div className={this.state.isEnglish ? 'title visible' : 'title hidden'}>
                    Opening a company
                </div>
                <div className="open-content">
                    <p className={!this.state.isEnglish ? ' visible' : ' hidden'}>
                        Prolazak kroz celi administrativni process registracije preduzeca kada ste stranac, moze biti tezak zadatak. DIVIA KONSALTING DOO ima reputaciju pruzanja brzih I pouzdanih usluga u ovoj oblasti.
                        Rado cemo Vam dati smernice kroz Srpske privredne zakone, kako biste mogli razumeti I odabrati najbolju opciju koja odgovara Vasem poslovanju po pitanju poreza I upravljanja.
                        Sve korake registracije kompanije, vrsimo mi u Vase ime, bez potrebe za Vasim fizickim prisustvom u bilo kom trenutku.
                        <br />
                    </p>
                    <p className={this.state.isEnglish ? ' visible' : ' hidden'}>
                        Going through the entire administrative process of company registration when you are a foreigner can be a difficult task. DIVIA KONSALTING DOO has a reputation for providing fast and reliable services in this area.
                        We will be happy to give you guidelines through the Serbian economic laws, so that you can understand and choose the best option that suits your business in terms of taxes and management.
                        We perform all steps of company registration on your behalf, without the need for your physical presence at any time.
                        <br />
                    </p>
                </div>
            </div>
        );
    }
}
export default BussinessConsult