import React from "react";
import Navbar from "../components/molecules/navbar";

class Invest extends React.PureComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          isEnglish: false,
        };
      }
    render() {
        return (
            <div className="new-page invest">
                <div className="button-div" onClick={()=>this.setState({isEnglish: !this.state.isEnglish})}>
                  <p className={!this.state.isEnglish ? 'active' : 'passive'}>SR</p>
                  <p className={this.state.isEnglish ? 'active' : 'passive'}>EN</p>
                </div>
                <Navbar isEnglish={this.state.isEnglish}></Navbar>
                <div className={!this.state.isEnglish ? 'title visible' : 'title hidden'}>
                    Investiranje
                </div>
                <div className={this.state.isEnglish ? 'title visible' : 'title hidden'}>
                    Investment
                </div>
                <div className="invest-content">
                    <p className={!this.state.isEnglish ? 'visible' : 'hidden'}>
                        ZA PRIVATNE INVESTICIJE

                        DIVIA CONSALTING DOO nudi integrisane konsultantske usluge koje imaju za cilj razvoj poslovanja. Kompanija obezbjeđuje mogućnost finansiranja ulaganja, pripremanje, podnošenje i implementaciju sufinansiranih projekata za nova i postojeća preduzeća.

                        DIVIA CONSALTING DOO je posvećen promociji i aktivnom učešću u međunarodnim mrežama saradnje kroz Evropske projekate.

                        Konkretno, DIVIA Consulting DOO SRBIJA nudi sljedeće usluge:

                        Procena podobnosti investicionog projekta

                        Izrada relevantnog poslovnog plana

                        Priprema i podnošenje projekta

                        Pružanje podrške umrežavanju preduzeća iz privatnog sektora sa institucijama javnog sektora

                        Upravljanje projektom i profesionalno praćenje implementacije nakon završetka projekta

                        Podršku tokom isplate donacija

                        <br />
                    </p>
                    <p className={this.state.isEnglish ? 'visible' : 'hidden'}>
                        FOR PRIVATE INVESTMENTS
                        DIVIA CONSALTING DOO offers integrated consulting services aimed at business development. The company provides the possibility of investment financing, preparation, submission and implementation of co-financed projects for new and existing companies.
                        DIVIA CONSALTING DOO is dedicated to promotion and active participation in international cooperation networks through European projects.
                        Specifically, DIVIA Consulting DOO SERBIA offers the following services:
                        Assessment of suitability of the investment project
                        Creation of a relevant business plan
                        Preparation and submission of the project
                        Providing support to the networking of private sector companies with public sector institutions
                        Project management and professional implementation monitoring after the project is completed
                        Support during the payment of donations
                        <br />
                    </p>
                </div>
            </div>
        );
    }
}
export default Invest