import React from "react";
import Navbar from "../components/molecules/navbar";

class Test extends React.PureComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          isEnglish: false,
        };
      }
    render() {
        return (
            <div className="new-page">
                <div className="button-div" onClick={()=>this.setState({isEnglish: !this.state.isEnglish})}>
                  <p className={!this.state.isEnglish ? 'active' : 'passive'}>SR</p>
                  <p className={this.state.isEnglish ? 'active' : 'passive'}>EN</p>
                </div>
                <Navbar isEnglish={this.state.isEnglish}></Navbar>
                <div className="title">
                    Marketing
                </div>
                <div className="new-page-content">
                    <p className={!this.state.isEnglish ? 'visible' : 'hidden'}>
                        U okviru svoje poslovne ponude, pruzamo I usluge kreiranje marketing planova, nezavisno od velicine I oblika organizovanosti privrednog subjekta. Saradnjom sa nasim klijentima stvaramo ponudu njihovog proizvodno – usluznog asortimana, koja je u skladu sa veoma izbirljivim zahtevima potrosaca. Zahvaljujuci analizama koje obavljamo, nasi brojni klijenti su ostvarili veoma uspesnu penetraciju postojecih I ulazak na nova trzista, u relativno kratkom period su se veoma uspesno pozicionirali na regionalnom nivou, ne skrivajuci svoje dalje trzisne aspiracije.
                        Marketing plan je document koji privrednog subjekta upucuje na situaciju u kojoj se nalazi, analizirajuci pri tom potrosace, konkurenciju I eksternu sredinu I obezbedjujuci pri tom upustva za realizaciju postavljenih ciljeva, I definisuci akcije marketing aktivnosti kao I alokaciju resursa u odredjenom period.
                        Jezgro analize u marketing planu je concept 4P. Ovaj concept cine proizvod (Product), cena (Price), distribucija (Place), promocija (Promotion).
                        <br />
                    </p>
                    <p className={this.state.isEnglish ? 'visible' : 'hidden'}>
                    As part of our business offer, we also provide services for the creation of marketing plans, regardless of the size and form of organization of the business entity. By cooperating with our clients, we create an offer of their product and service assortment, which is in accordance with the very demanding requirements of consumers. Thanks to the analyzes we perform, our many clients have achieved a very successful penetration of existing and entry into new markets, in a relatively short period they have positioned themselves very successfully at the regional level, not hiding their further market aspirations.
                        A marketing plan is a document that guides a business entity to the situation it is in, analyzing consumers, competition and the external environment, providing instructions for the realization of set goals, and defining actions for marketing activities as well as the allocation of resources in a certain period.
                        The core of the analysis in the marketing plan is the 4P concept. This concept consists of Product, Price, Place, Promotion.
                        <br />
                    </p>
                </div>
            </div>
        );
    }
}
export default Test