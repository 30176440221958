import React from "react";
import 'bulma/css/bulma.min.css';
import Navbar from "../components/molecules/navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faLocationArrow, faMobile } from '@fortawesome/free-solid-svg-icons'
import { AddressMap } from "../components/molecules/addressMap";

var isEnglish
class Template extends React.PureComponent {
  
  constructor(props) {
    super(props);

    this.state = {
      isEnglish: false,
    };
  }

  render() {
    return (
      <div>
        <Navbar isEnglish={this.state.isEnglish}></Navbar>
                <div className="button-div" onClick={()=>this.setState({isEnglish: !this.state.isEnglish})}>
                  <p className={!this.state.isEnglish ? 'active' : 'passive'}>SR</p>
                  <p className={this.state.isEnglish ? 'active' : 'passive'}>EN</p>
                </div>
        <section className="hero is-medium">
          <div class="hero-body main-logo-hero-body">
            <div class="container main-logo-container">
              <div className='text-box'>
                <p className={!this.state.isEnglish ? 'visible' : 'hidden'}>DIVIA KONSALTING
                  ADMINISTRACIJA <br></br>I KONSALTING IZ RADNIH DOMENA
                </p>
                <p  className={this.state.isEnglish ? 'visible' : 'hidden'}>
                  DIVIA CONSULTING ADMINISTRATION<br></br>
                  AND CONSULTING FROM WORK DOMAINS
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="parallax-1" class="hero is-large ">
          <div class="hero-body">
            <div class="container">
              <div class="columns">
                <div class="column is-6 is-offset-6">
                  <h1 className={!this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Vize</h1>
                  <h1 className={this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Visas</h1>
                  <hr class="content-divider" />
                  <h2 class="subtitle">
                    <p className={!this.state.isEnglish ? 'visible' : 'hidden'}>Izdavanje viza za duži ili kraći boravak</p>
                    <p className={this.state.isEnglish ? 'visible' : 'hidden'}>Issuance of visas for a longer or shorter stay</p>
                    <br></br>
                    <p className={!this.state.isEnglish ? 'visible' : 'hidden'}>Viza C - Može biti izdata kao turistička poslovna ili kategorisana kao druga putovanja za jedan dva ili više ulazaka u periodu do 90 dana.</p>
                    <p className={!this.state.isEnglish ? 'visible' : 'hidden'}>Viza D - Duža verzija vize C u kojoj vam je odobreno da boravite u našoj Republici čak do 180 dana.</p>
                    <p className={this.state.isEnglish ? 'visible' : 'hidden'}>Visa C - Can be issued as tourist business or categorized as other trips for one, two or more entries in a period of up to 90 days.</p>
                    <p className={this.state.isEnglish ? 'visible' : 'hidden'}>Visa D - A longer version of visa C in which you are allowed to stay in our Republic for up to 180 days.</p>

                  </h2>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="parallax-2" class="hero is-large ">
          <div class="hero-body">
            <div class="container">
              <div class="columns">
                <div class="column is-6">
                  <h1 className={!this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Radne Dozvole</h1>
                  <h1 className={this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Work permits</h1>
                  <hr class="content-divider" />
                  <h2 className={!this.state.isEnglish ? 'subtitle visible' : 'subtitle hidden'}>Ako niste srpski drzavljanin, ali zelite raditi u Srbiji, trebace Vam radna dozvola.
                    U mogucnosti smo da Vam pruzimo smernice kroz citavi process, kao I da se apliciramo u Vase ime, kako biste obezbedili najpovoljniji ishod I brz process apliciranja.
                  </h2>
                  <h2 className={this.state.isEnglish ? 'subtitle visible' : 'subtitle hidden'}>If you are not a Serbian citizen, but want to work in Serbia, you will need a work permit.
                    We are able to provide you with guidelines throughout the entire process, as well as to apply on your behalf, in order to ensure the most favorable outcome and a quick application process.
                  </h2>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="parallax-3" class="hero is-large ">
          <div class="hero-body">
            <div class="container">
              <div class="columns">
                <div class="column is-6 is-offset-6">
                  <h1 className={!this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Boravak</h1>
                  <h1 className={this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Stay</h1>
                  <hr class="content-divider" />
                  <h2 className={!this.state.isEnglish ? 'subtitle visible' : 'subtitle hidden'}>Ako ste odlucili da se preselite u Srbiju zbog zaposlenja, da biste se pridruzili svojoj porodici ili da biste ziveli sa partnerom, odnosno koristili Vasu nepokretnost bez vremenskog ogranicenja boravka, verovatno ce Vam trebati boravisna dozvola.
                    Kao iskusna konsultantska kuca u oblasti srpskog imigracionog zakona, u mogucnosti smo da Vam pruzimo jednostavan I brz process prijave za privremene I stalne boravisne dozvole .
                  </h2>
                  <h2 className={this.state.isEnglish ? 'subtitle visible' : 'subtitle hidden'}>If you have decided to move to Serbia for employment, to join your family or to live with your partner, or to use your real estate without a time limit on your stay, you will probably need a residence permit.
                  As an experienced consulting firm in the field of Serbian immigration law, we are able to provide you with a simple and quick application process for temporary and permanent residence permits.
                  </h2>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="parallax-4" class="hero is-large ">
          <div class="hero-body">
            <div class="container">
              <div class="columns">
                <div class="column is-6">
                  <h1 className={!this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Ljudski Resursi</h1>
                  <h1 className={this.state.isEnglish ? 'title is-1 visible' : 'title is-1 hidden'}>Human Resources</h1>
                  <hr class="content-divider" />
                  <h2 className={!this.state.isEnglish ? 'subtitle visible' : 'subtitle hidden'}>
                    Upravljanje ljudskim resursima
                    Brinuti se o svojoj kompaniji znaci voditi racuna o svojim zaposlenima, kao njenoj najvrednijoj imovini.
                    Pruzamo usluge upravljanja ljudskim resursima, koje se odnose na:
                    Primenu propisa iz oblasti radnih odnosa.
                    Izradu resenja o pravima obavezama iz rada I po osnovu rada.
                    Izradu ugovora o radu.
                    Izradu resenja o godisnjem odmoru.
                    Izradu pravilnika o organizaciji I sistematizaciji.
                    Pisanjem zalbi I tuzbi.
                  </h2>
                  <h2 className={this.state.isEnglish ? 'subtitle visible' : 'subtitle hidden'}>
                    Human Resource Management
                    Taking care of your company means taking care of your employees, as its most valuable asset.
                    We provide human resources management services related to:
                    Application of regulations in the field of labor relations.
                    Creation of decisions on rights and obligations from work and on the basis of work.
                    Drafting of employment contracts.
                    Making decisions about annual leave.
                    Creation of regulations on organization and systematization.
                    By writing appeals and lawsuits.
                  </h2>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="cta va">
          <div class="container">
            <div class="columns">
              <div class="column is-6">
                <div className="empty-box">
                  <AddressMap />
                </div>
              </div>
              <div class="column is-6 contact-info trans-window">
                <br className="mobile" />
                <div class="field">
                  <FontAwesomeIcon icon={faLocationArrow} />
                  <p className="contact-text">Srbija, Sremska Mitrovica, Marka Aurelija BB</p>
                </div>
                <br className="web" />
                <div class="field">
                  <FontAwesomeIcon icon={faMobile} />
                  <p className="contact-text">062/899/0975</p>
                </div>
                <br className="web" />
                <div class="field">
                  <FontAwesomeIcon icon={faComment} />
                  <p className="contact-text">info@divia-consult.com</p>
                </div>
                <br className="web" />
              </div>
            </div>
          </div>

        </section>
        <footer class="footer">
          <div class="content has-text-centered">
            <p>
              Copyright © 2022 - Divia.com
            </p>
          </div>
        </footer>

      </div>
    )
  }
}


export default Template;
