import React from "react";
import Navbar from "../components/molecules/navbar";

class LegalConsult extends React.PureComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          isEnglish: false,
        };
      }
    render() {
        return (
            <div className="new-page legal">
                <div className="button-div" onClick={()=>this.setState({isEnglish: !this.state.isEnglish})}>
                  <p className={!this.state.isEnglish ? 'active' : 'passive'}>SR</p>
                  <p className={this.state.isEnglish ? 'active' : 'passive'}>EN</p>
                </div>
                <Navbar isEnglish={this.state.isEnglish}></Navbar>
                <div className={!this.state.isEnglish ? 'title visible' : 'title hidden'}>
                    Pravna pomoc
                </div>
                <div className={this.state.isEnglish ? 'title visible' : 'title hidden'}>
                    Legal help
                </div>
                <div className="legal-content">
                    <p className={!this.state.isEnglish ? ' visible' : ' hidden'}>
                        Da biste upravljali svojim novoosnovanim preduzecem kako treba, morate znati sta Vam njegovi brojevi govore.

                        Rado cemo Vam pruziti pomoc u racunovodstvenim uslugama koje odgovaraju potrebama Vaseg poslovanja.

                        Od ukupnih tacnih izvestaja o perfomansama kompanije, do zakonskih zahteva kao sto su registracija kod relevantnog poreskog organa I mesecnih plata I placanja poreza, mi smo vest tim koji Vam moze pruziti mir kada se radi o finansijama Vase kompanije.

                        <br />
                    </p>
                    <p className={this.state.isEnglish ? ' visible' : ' hidden'}>
                        In order to manage your new business properly, you need to know what its numbers are telling you.
                        We will be happy to help you with accounting services that meet the needs of your business.
                        From overall accurate reports on the company's performance, to legal requirements such as registration with the relevant tax authority and monthly salaries and tax payments, we are the news team that can give you peace of mind when it comes to your company's finances.

                        <br />
                    </p>
                </div>
            </div>
        );
    }
}
export default LegalConsult