import React from 'react';
import '../../styles/reset.scss';
import 'bulma/css/bulma.min.css';
import '../../styles/navbar.scss';
import '../../templates/template1.js';
import Template from '../../templates/template1.js';
import '../../styles/App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Test from '../../pages/Test';
import Invest from '../../pages/Invest';
import LegalConsult from '../../pages/LegalConsult';
import BussinessConsult from '../../pages/BussinessConsult';

export default function App() {
  // let template1 = Template;
  return (
    <BrowserRouter>
      <Routes >
        <Route exact path="/" element={<Template />}/>
        <Route exact path='/marketing' element={<Test/>}/>
        <Route exact path='/invest' element={<Invest/>}/>
        <Route exact path='/legal' element={<LegalConsult/>}/>
        <Route exact path='/bussines' element={<BussinessConsult/>}/>
      </Routes>
    </BrowserRouter>
  );
}