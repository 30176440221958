import React from "react";

const Navbar = ({isEnglish = false}) => {
  function toggleDropdown() {
    const myNav = document.querySelector("#my-nav")
    const burger = document.querySelector("#burger")
    const navMenu = document.querySelector("#navMenu")
    myNav.classList.toggle("is-active")
    burger.classList.toggle("is-active")
    navMenu.classList.toggle("is-active")
  }

  return (
    <nav class="navbar" id="my-nav" role="navigation">
      <div class="navbar-menu" id="navMenu">
        <div class="navbar-start" className={!isEnglish ? 'navbar-start visible' : 'hidden'}>
          <a href="/#parallax-1" class="navbar-item">Vize</a>
          <a href="/#parallax-2" class="navbar-item">Radne Dozvole</a>
          <a href="/#parallax-3" class="navbar-item">Boravak</a>
          <a href="/#parallax-4" class="navbar-item">Ljudski Resursi</a>
          <a href="/#contact" class="navbar-item">Kontakt</a>
          <a href="/marketing" class="navbar-item">Marketing</a>
          <a href="/invest" class="navbar-item">Investiranje</a>
          <a href="/legal" class="navbar-item">Pravna pomoć</a>
          <a href="/bussines" class="navbar-item">Otvaranje firme</a>
        </div>
        <div class="navbar-start" className={isEnglish ? 'navbar-start visible' : 'hidden'}>
          <a href="/#parallax-1" class="navbar-item">Visas</a>
          <a href="/#parallax-2" class="navbar-item">Work Permits</a>
          <a href="/#parallax-3" class="navbar-item">Stay</a>
          <a href="/#parallax-4" class="navbar-item">Human Resources</a>
          <a href="/#contact" class="navbar-item">Contact</a>
          <a href="/marketing" class="navbar-item">Marketing</a>
          <a href="/invest" class="navbar-item">Investment</a>
          <a href="/legal" class="navbar-item">Legal Help</a>
          <a href="/bussines" class="navbar-item">Opening a company</a>
        </div>
        <div className="navbar-start">
          <div class="navbar-brand">
            <a href="/">
              <div className="test"></div>
            </a>
          </div>
        </div>
      </div>
      <div role="button" id="burger" class="navbar-burger" onClick={toggleDropdown}>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </div>
    </nav>
    // <nav className="navbar">
    //   <div class="navbar-brand mobile">
    //     <a href="/">
    //         <div className="test"></div>
    //     </a>
    //   </div>
    //   <div className="link-container">
    //     <a href="/#parallax-1">Vize</a>
    //     <a href="/#parallax-2">Radne Dozvole</a>
    //     <a href="/#parallax-3">Boravak</a>
    //     <a href="/#parallax-4">Ljudski Resursi</a>

    //     <a href="/#contact">Kontakt</a>
    //     <a href="/marketing">Marketing</a>
    //     <a href="/invest">Investiranje</a>
    //     <a href="/legal">Pravna pomoć</a>
    //     <a href="/bussines">Otvaranje firme</a>
    //   </div>
    //   <div class="navbar-brand web">
    //     <a href="/">
    //         <div className="test"></div>
    //     </a>
    //   </div>
    // </nav>
  );
};

export default Navbar;
